import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './dds.js'

window.addEventListener('load', function() {

const nextPlays = window.nextPlays;
const calcDDTable = window.calcDDTable;


class Card extends React.Component {
  rendercenters(index,suit){
    var output=[]
    var i
    var number = index
    const deck = [[[],[0,1,0],[]],[[],[1,1],[]],[[],[1,1,1],[]],[[1,1],[],[1,1]],[[1,1],[0,1,0],[1,1]],[[1,1,1],[0],[1,1,1]],[[1,1,1],[0,1,0,0,0],[1,1,1]],[[1,1,1],[0,1,1,0],[1,1,1]],[[1,1,1,1],[0,1,0],[1,1,1,1]],[[1,1,1,1],[0,1,0,1,0],[1,1,1,1]]]
    if(index ==='A'){number =1}
    const card = deck[number-1]
    if(number<11){
    for (i=0; i<3; i++){
      output.push(<div className = 'card-core-sub'>
              {this.rendercentersinner(card[i],suit)}
          </div>);}}
      else {
        output.push(<><div className = 'card-core-sub'>
              {this.rendercentersinner([1,0,0],suit)}
          </div>
          <img src = {'https://s3-eu-west-1.amazonaws.com/jameskeiller.com/images/playingcards/croped/hearts_'+number+'.svg'} width = '100%' className = 'card-picture'>
          </img>
          <div className = 'card-core-sub invert'>
              {this.rendercentersinner([1,0,0],suit)}
          </div></>);
      }
  return  output
  }
  rendercentersinner(array,suit){
    var object = [];
    var i;
    for (i=0; i < array.length;i++){
      if (array[i]===1){
      object.push(<div className = 'innercell'>{suit}</div>)}
      else {object.push(<div className = 'innercell'></div>)}
    }
    return object
  }
  render() {
    var number = this.props.value
    var type
    var cardclass
    var hover = ''
    var trumps = ' nontrump'

    if (this.props.suit === 3) {
    type ="♣";
    cardclass = 'card'
    } else if (this.props.suit === 2) {
    type =  "♦"
    cardclass = 'card red';
    } else if (this.props.suit === 1) {
   type ="♥";
    cardclass = 'card red';
    } else if (this.props.suit === 0){
    type ="♠";
    cardclass = 'card'}

    if(this.props.suit === this.props.trumps){cardclass += ' trump'}
    else {cardclass += ' nontrump'}

    if (number === 14) {
      number ="A";
    } else if (number < 11) {
    } else if (number === 11) {
      number ="J";
    } else if (number === 12) {
      number ="Q";
    }else if (number === 13) {
      number ="K";
    };
    var   lstyle = {}
    if (this.props.type === 'hand'){hover = 'cardhover cardhand';
    lstyle = {webkittransform: 'rotate('+this.props.rotate*7+'deg)',
           transform: 'rotate('+this.props.rotate*7+'deg)',
           marginTop : 1.5*this.props.rotate*this.props.rotate
         };
  }else if(this.props.type === 'dummy'){hover = 'carddummy';}


    var ustyle ={}
    if(Number.isInteger(this.props.order)){ustyle.zIndex = this.props.order};
    if (this.props.win===1){
      lstyle.boxShadow = '0px 0px 36px #FEC780'
    }

    if(number){
    return (
      <div className = {hover} style = {ustyle}>
      <div className = {cardclass} onClick={this.props.onClick} style = {lstyle}>
        <div className = 'card-side'>
          <div className ='vertcell'>
          {number}
          </div>
          <div className ='vertcell'>
          {type}
          </div>
        </div>
        <div className = 'card-core'>
              {this.rendercenters(number,type)}
        </div>
        <div className = 'card-side invert'>
          <div className ='vertcell'>
          {number}
          </div>
          <div className ='vertcell'>
          {type}
          </div>
        </div>
      </div>
        </div>
    );}
    else {return(<div className = 'emptycard'><p></p></div>)};
  }
};

class Calulateprobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {index:0}
    }
  render(){
  var suits = this.props.suits
  var hands = this.props.hand
  var index = this.state.index
  function choose(n,k){

    var out = 0;
    var num = 1;
    var den = 1;
    var upper = Math.min(k, n - k);
    var i=1;
    if (0<=k<= n){for(i=1;i<upper+1; i++){
      num *= n
      den *= i
      n -= 1
    }
    out +=num/den
    }
    return out
  }
  function probpart(p,h){

    var q = [h[0]-p[0],h[1]-p[1]]
    var x= p[0] +p[1]
    var y= q[0] +q[1]
    var t= h[0] +h[1]
    var out = choose(x,p[0])*choose(y,q[0])
    var div = choose(t,h[0])
    return out/div
  }
  function partitions(x,n){
    var out = []
    var i=0
    var j = []
    var k=[]
    if (n > 1){
        for (i=0;i<x+1;i++){
            partitions(x-i,n-1).forEach(j =>{
                k = j.slice();
                k.push(i);
                out.push(k);
              })}}
    else{out.push([x])}
    return out
  }
  var output = (<div style = {{border: '1px solid lightgrey',  borderRadius: '5px', background: '#fff'}}>
  <div className = 'statsType'><div style = {{textAlign: 'center'}}>{suits[index]+' '+['Spades','Hearts','Diamonds','Clubs'][index]}</div>{partitions(suits[index],2).map(y => {return(<div className = 'statsRow'>
      <div>{y[0].toString()+' - '+y[1].toString()}</div> <div>{(probpart(y,hands)*100).toFixed(0)+'%'}</div></div>)}
    )}
  </div>
  <div className="littlebutton" style = {{borderRadius: '5px'}} onClick={() => this.setState({index: (index+1)%(suits.length)})}>Next Suit</div>
  </div>)

    return (output)


}}

function shuffledeck(){
  var deck = [];
  var i;
  var j;
  var k;
  for (i = 2; i < 15; i++) {
     for (j = 0; j < 4; j++) {
       deck.push([i,j,0,0])
       }};
  for (i = deck.length -1; i > 0; i--) {
  j = Math.floor(Math.random() * i)
  k = deck[i]
  deck[i] = deck[j]
  deck[j] = k
}
  return deck
}

function pseudoshuffledeck(trumps,minpoints,minlength,maxpoints,maxlength){
  var player = [];
  var deck = [];
  var cards = [];
  var currentpoints = 0;
  var currentlength = 0;
  var i;
  var j;
  var k;
  //create high cards
  for (i = 11; i < 15; i++) {
     for (j = 0; j < 4; j++) {
        cards.push([i,j,0,0])
      }};
  //shuffle high cards
  for (i = cards.length -1; i > 0; i--) {
  j = Math.floor(Math.random() * i)
  k = cards[i]
  cards[i] = cards[j]
  cards[j] = k
}
// remove high cards and place in player hand until points min reached
while (currentpoints<minpoints){
  var card= cards.pop()
  player.push(card);
  currentpoints+=(card[0]-10);
  if(card[1]===trumps){currentlength++}
}

// add low cards
  for (i = 2; i < 11; i++) {
      for (j = 0; j < 4; j++) {
        cards.push([i,j,0,0])
        }};

//shuffle remaining cards
  for (i = cards.length -1; i > 0; i--) {
  j = Math.floor(Math.random() * i)
  k = cards[i]
  cards[i] = cards[j]
  cards[j] = k
      }
// loop through adding enough trump cards to player
i = cards.length-1
while (currentlength<minlength ){
  if (cards[i][1]===trumps & (currentpoints<(maxpoints-4)|| cards[i][0]<11)){
    var card= cards.splice(i,1)[0]
    player.push(card);
    currentlength++
    currentpoints+=Math.max((card[0]-10),0);
  }
  i--
}

// fill remaining cards
i = cards.length-1;
while (player.length<26){
  if ((currentpoints<(maxpoints-4)|| cards[i][0]<11)&(currentlength<maxlength|| cards[i][1]!=trumps)){
    var card= cards.splice(i,1)[0]
    player.push(card);
    currentpoints+=Math.max((card[0]-10),0);
    currentlength+= (card[1]===trumps);
  }
  i--
}

//shuffle player cards

for (i = player.length -1; i > 0; i--) {
j = Math.floor(Math.random() * i)
k = player[i]
player[i] = player[j]
player[j] = k
}

//join decks together playercards go in 0-12 and 26-38
deck = player.splice(0,13);
deck = deck.concat(cards.splice(0,13));
deck = deck.concat(player);
deck = deck.concat(cards);


  return deck
}

function InputValue(props){
return (
<div>
  <div className="flexRow">
    <div style={{width:'61%' }} >{props.name}</div>
    <div style={{width:'13%'}} className="littlebutton" onClick={() => props.decrement()}>⇦</div>
    <div style={{width:'13%', textAlign: 'center'}}>{props.value}</div>
    <div style={{width:'13%'}} className="littlebutton" onClick={() => props.increment()}>⇨</div>
  </div>
</div>

)

}

class LevelMaker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {minpoints:20,
                  maxpoints:28,
                  minlength:7,
                  maxlength:9
                  }
    }
  increment(value){
    var state = this.state
    if (value === 'minpoints'){if(state.minpoints<36){state.minpoints++};
                               if(state.maxpoints<state.minpoints+4){state.maxpoints++}}
    if (value === 'maxpoints'){if(state.maxpoints<40){state.maxpoints++};}
    if (value === 'minlength'){if(state.minlength<13){state.minlength++};
                               if(state.maxlength<state.minlength){state.maxlength++}}
    if (value === 'maxlength'){if(state.maxlength<13){state.maxlength++};}
    this.setState({state});
  }
  decrement(value){
    var state = this.state
    if (value === 'minpoints'){if(state.minpoints>0){state.minpoints--};}
    if (value === 'maxpoints'){if(state.maxpoints>4){state.maxpoints--};
                              if(state.minpoints>state.maxpoints-4){state.minpoints--}
  }
    if (value === 'minlength'){if(state.minlength>4){state.minlength--};
                               }
    if (value === 'maxlength'){if(state.maxlength>4){state.maxlength--};
                               if(state.minlength>state.maxlength){state.minlength--}}
    this.setState({state});
  }
  render(){
  var value1
  var pbn
  var trumps
  var minpoints = this.state.minpoints
  var minlength = this.state.minlength
  var maxpoints = this.state.maxpoints
  var maxlength = this.state.maxlength
  return (
    <div className="LevelCard" >
      <div className="LevelImage">
        <div>
          <InputValue value = {minpoints} name = 'Min HCP' increment = {() => this.increment('minpoints')} decrement ={() => this.decrement('minpoints')}/>
          <InputValue value = {maxpoints} name = 'Max HCP' increment = {() => this.increment('maxpoints')} decrement ={() => this.decrement('maxpoints')}/>
        </div>
        <div>
          <InputValue value = {minlength} name = 'Min # Trumps' increment = {() => this.increment('minlength')} decrement ={() => this.decrement('minlength')}/>
          <InputValue value = {maxlength} name = 'Max # Trumps' increment = {() => this.increment('maxlength')} decrement ={() => this.decrement('maxlength')}/>
        </div>
      </div>
      <div className="LevelInfo" onClick={() =>this.props.onClick(pbn,trumps,minpoints,minlength,maxpoints,maxlength)}>
        Generate Contract
      </div>
    </div>
  );}
}


function LevelCard(props){
  var pbn = ''
  var trumps = 0
  var description = 'This contract will be randomly generated with random cards';
  var name = 'Random Level';
  if (props.hasOwnProperty('data')){
  pbn = props.data.pbn;
  trumps = props.data.trumps;
  if (props.data.hasOwnProperty('description')){ description = props.data.description}
  if (props.data.hasOwnProperty('description')){ name = props.data.name}
  }
  return (
    <div className="LevelCard" onClick={() =>props.onClick(pbn,trumps)}>
      <div className="LevelImage">
      {description}
      </div>
      <div className="LevelInfo">
        {name}
      </div>
    </div>
  );
}

function Selector(props) {
  var data = props.data;
  var i =0;
  var levels = [];
  var out

    levels.push(<LevelMaker onClick = {props.onClick}/>)
  for(i=0;i<data.length;i++){
    levels.push(<LevelCard data = {data[i]} onClick = {props.onClick}/>)
  }
  return (
    <div className="LevelContainer" >
      {levels}
    </div>
  );
};

class Board extends React.Component {
  constructor(props) {
    super(props);
    //const deck = shuffledeck();
    const deck = props.deck;
    const sortedDeck = [deck.slice(0,13).sort(function(a, b){return (13*a[1]+a[0]) - (13*b[1]+b[0])}),
                deck.slice(13,26).sort(function(a, b){return (13*a[1]+a[0]) - (13*b[1]+b[0])}),
                deck.slice(26,39).sort(function(a, b){return (13*a[1]+a[0]) - (13*b[1]+b[0])}),
                deck.slice(39,52).sort(function(a, b){return (13*a[1]+a[0]) - (13*b[1]+b[0])})];
    const trumps = props.trumps;
    const lead = 3;
    this.state = {
      bestscore : 13,  //doesnt need to be an array as doesnt update
      EWscore:[0],
      NSscore:[0],
      sortedDeck: [sortedDeck,sortedDeck],
      playedHistory: [],
      currentplay:[[],[],[],[]],  //doesnt need to be an array as doesnt update but could be added in to played history
      currentplayer:[lead,lead],
      pointinhand:0,  //doesnt need to be an array as doesnt update
      trumps:trumps,  //not an array
      time:0   //not an array
    };
  }

  componentDidMount(){this.doAi(this.state.currentplayer[this.state.time+1],this.state.sortedDeck[this.state.time+1][this.state.currentplayer[this.state.time+1]],
    this.generatePBN(this.state.currentplayer[this.state.time], this.state.sortedDeck[this.state.time]),this.state.trumps,this.state.currentplay,this.state.pointinhand);
  };

  componentDidUpdate(prevProps) {
  // Typical usage (don't forget to compare props):
  if (this.props.deck !== prevProps.deck) {
    const deck = this.props.deck;
    const sortedDeck = [deck.slice(0,13).sort(function(a, b){return (13*a[1]+a[0]) - (13*b[1]+b[0])}),
                deck.slice(13,26).sort(function(a, b){return (13*a[1]+a[0]) - (13*b[1]+b[0])}),
                deck.slice(26,39).sort(function(a, b){return (13*a[1]+a[0]) - (13*b[1]+b[0])}),
                deck.slice(39,52).sort(function(a, b){return (13*a[1]+a[0]) - (13*b[1]+b[0])})];
    const trumps = this.props.trumps;
    const lead = 3;
    this.setState({bestscore : 13,  //doesnt need to be an array as doesnt update
    EWscore:[0],
    NSscore:[0],
    sortedDeck: [sortedDeck,sortedDeck],
    playedHistory: [],
    currentplay:[[],[],[],[]],  //doesnt need to be an array as doesnt update but could be added in to played history
    currentplayer:[lead,lead],
    pointinhand:0,  //doesnt need to be an array as doesnt update
    trumps:trumps,  //not an array
    time:0   //not an array})
  },() => {this.doAi(this.state.currentplayer[this.state.time+1],this.state.sortedDeck[this.state.time+1][this.state.currentplayer[this.state.time+1]],
    this.generatePBN(this.state.currentplayer[this.state.time], this.state.sortedDeck[this.state.time]),this.state.trumps,this.state.currentplay,this.state.pointinhand)}
  )
}}

//these functions do not mutate state (except doAi, but that needs to be fixed at somepoint)

  generatePBN(lead, sortedDeck){
    var cardRank = [2,3,4,5,6,7,8,9,'T','J','Q','K','A']
    var positions = ['N','E','S','W']
    const pbn = positions[lead]+':'+([lead,(lead+1)%4,(lead+2)%4,(lead+3)%4].map(l => [0,1,2,3].map(i =>  sortedDeck[l].filter(x => x[1]===i).map(x =>cardRank[x[0]-2]).reverse().join('')).join('.')).join(' ') )
    console.log(pbn)
    return pbn ;
  }

  generateHandPlays(currentplay,pointinhand,currentplayer){
    var out = []
    var cardMap = [[0,1,2,3,4,5,6,7,8,9,'T','J','Q','K','A'],['S','H','D','C']]
    var i = 0;
    for (i=0; i<pointinhand; i++){
      out.push([0,1].map(j => cardMap[j][currentplay[(currentplayer-pointinhand+i+4)%4][j]]).join(''))
    }
    return out;
  }

  checkWinner(currentPlay,trumps,currentPlayer,pointInHand){
    var playedhand = currentPlay.slice()
    const firstplayer = (currentPlayer - pointInHand + 4)%4;
    const handsuit = currentPlay[firstplayer][1];
    playedhand.sort(function(a, b){
      var c = a.slice();
      var d = b.slice();
      //make first suit high and trump suit high then do normal sort
      if (a[1] === handsuit){c[1]=4};
      if (b[1] === handsuit){d[1]=4};
      if (a[1] === trumps){c[1]=5};
      if (b[1] === trumps){d[1]=5};
      return (13*c[1]+c[0]) - (13*d[1]+d[0])});
    return currentPlay.indexOf(playedhand[3]);
  }

  doAi(player,playerhand,pbn,trumps,currentplay,pointinhand){

    var bestmove = nextPlays(pbn,['S','H','D','C','N'][trumps],this.generateHandPlays(currentplay,pointinhand,player)).plays.sort(function(a, b){return (b.score) - (a.score)})[0];
    if(this.state.time===0 & pointinhand===0){this.state.bestscore = 13-bestmove.score;}  //need to pull this out, but ok for now as neither are arrays.
    var i = 0;
    for (i=0; i <playerhand.length; i++){
      if(playerhand[i][0]== ['0','1','2','3','4','5','6','7','8','9','T','J','Q','K','A'].indexOf(bestmove.rank)
      & playerhand[i][1]===['S','H','D','C'].indexOf(bestmove.suit)
    ){
      this.playCard(i,player)
    }
    }
  }

  checkMove(i,position,pointinhand,currentplay,playerhand){
    const firstplayer = (position - pointinhand + 4)%4;
    const handsuit = currentplay[firstplayer][1];
    var voids = true;
    // check if void (as handsuit is undefined for first play you are always classed as void in that case which is what we want)
    playerhand.forEach(function(value){
      if(value[1]===handsuit){
        voids = false
      }})
    // if void return true else check played card is same suit
    if (voids === true){
      return true;
    } else if(playerhand[i][1]===handsuit){
      return true
    } else {return false}
  }

  getBoardState(time,playedHistory,currentplay){
    if (time<playedHistory.length){
      return (playedHistory[time])
    } else {return currentplay}
  }

  renderCards(i,position,type,hand) {
    const decksize = hand.length -1
    return (
      <Card
        value={hand[i][0]}
        suit = {hand[i][1]}
        type = {type}
        order ={i}
        trumps = {this.state.trumps}
        rotate = {i-decksize/2}
        onClick={() => this.playCard(i,position)}
      />
    );
  }

//these methods directly effect state

  playCard(i,position) {
    const time= this.state.time;

    //checks if you can play the card then plays the card
    if (this.checkMove(i,position,this.state.pointinhand,this.state.currentplay,this.state.sortedDeck[this.state.time][position]) && position===this.state.currentplayer[time+1] && this.state.time===this.state.playedHistory.length){
      const cards = this.state.sortedDeck[time+1].slice();
      const sorteddeck = this.state.sortedDeck;
      const pointInHand = this.state.pointinhand;
      const nextpointinhand = (pointInHand +1)%4
      var history = this.state.playedHistory
      var played = cards[position][i];
      var currentplayer = this.state.currentplayer;
      var initialplayer = currentplayer[time+1];
      var nextplayer = (initialplayer +1)%4;
      var currentplay = this.state.currentplay.slice();

      played[2]=pointInHand;  //add the order to the card that has been played
    //  cards[position].splice(i,1);    //remove the card played from the deck
      cards[position] = cards[position].slice(0,i).concat(cards[position].slice(i+1))
      currentplay[position] = played;   //add the played card to the table
      currentplay[position][3] = 0;

    // checks if this is the last card, if so finish hand else, proceed to next player
      if  (pointInHand === 3){
       var winner = this.checkWinner(currentplay,this.state.trumps,initialplayer,pointInHand); //identify the winner
       var ewscore = this.state.EWscore[time];
       var nsscore = this.state.NSscore[time];
       if (winner === 1 || winner === 3){this.state.EWscore.push(ewscore+1); this.state.NSscore.push(nsscore)} else {this.state.EWscore.push(ewscore); this.state.NSscore.push(nsscore+1)};
       //this.state.pbn = this.generatePBN(winner,cards);
       nextplayer = winner;
       currentplayer.push(nextplayer);
       //reset winner flag
       currentplay[winner][3] = 1; //set winner flag
       history.push(currentplay) ;  //adds player to history
       sorteddeck.push(cards);
       currentplay =[[],[],[],[]];
    }
    currentplayer[time+1] = nextplayer;
    sorteddeck[time+1] = cards;
    this.setState({sortedDeck: sorteddeck,
                    currentplay: currentplay,
                    playedHistory:history,
                    currentplayer:currentplayer,
                    pointinhand:nextpointinhand}, () => {if  ((time  === this.state.playedHistory.length)&(this.state.currentplayer[time+1] ===3 || this.state.currentplayer[time+1] ===1)){this.doAi(this.state.currentplayer[time+1],this.state.sortedDeck[this.state.time+1][this.state.currentplayer[time+1]],
                      this.generatePBN(this.state.currentplayer[this.state.time], this.state.sortedDeck[this.state.time]),this.state.trumps,this.state.currentplay,this.state.pointinhand)}}
);
  }
  }

  regressHistory(){
    const time = this.state.time;
    if (time > 0){
    this.setState({time: time - 1})}
  }

  playFromHere(){
    const time = this.state.time;
    //delete scores
    if (time  < this.state.playedHistory.length){
    const ewscore = this.state.EWscore.slice(0,time+2);
    const nsscore = this.state.NSscore.slice(0,time+2);
    //delete sortedDeck and copy current deck to next position
    var sortedDeck = this.state.sortedDeck.slice(0,time+2)
    sortedDeck.push(sortedDeck[time+1])
    //deletehistory and set currentplay to blank
    var playedHistory = this.state.playedHistory.slice(0,time+1)
    var currentplay = [[],[],[],[]]
    //delete curernt playerhand
    var currentplayer = this.state.currentplayer.slice(0,time+2)
    currentplayer.push(currentplayer[time+1])
    //set point in hand to zero
    var pointinhand = 0
    this.setState({EWscore: ewscore,
                  NSscore:nsscore,
                  sortedDeck:sortedDeck,
                  playedHistory:playedHistory,
                  currentplay:currentplay,
                  currentplayer:currentplayer,
                  pointinhand:pointinhand
                },function(){this.progressHistory()  });
  }}

  deleteFuture(){
    const time = this.state.time;
    //delete scores
    const ewscore = this.state.EWscore.slice(0,time+1);
    const nsscore = this.state.NSscore.slice(0,time+1);
    //delete sortedDeck and copy current deck to next position
    var sortedDeck = this.state.sortedDeck.slice(0,time+1)
    sortedDeck.push(sortedDeck[time])
    //deletehistory and set currentplay to blank
    var playedHistory = this.state.playedHistory.slice(0,time)
    var currentplay = [[],[],[],[]]
    //delete curernt playerhand
    var currentplayer = this.state.currentplayer.slice(0,time+1)
    currentplayer.push(currentplayer[time])
    //set point in hand to zero
    var pointinhand = 0
    this.setState({EWscore: ewscore,
                  NSscore:nsscore,
                  sortedDeck:sortedDeck,
                  playedHistory:playedHistory,
                  currentplay:currentplay,
                  currentplayer:currentplayer,
                  pointinhand:pointinhand
                },function(){
                const player = this.state.currentplayer[this.state.time+1];
                if  (player ===3 || player ===1){
                  this.doAi(player,this.state.sortedDeck[this.state.time+1][player],this.generatePBN(this.state.currentplayer[this.state.time], this.state.sortedDeck[this.state.time]),this.state.trumps,this.state.currentplay,this.state.pointinhand,player);
                }
              })


  }

  progressHistory(){
    const time = this.state.time;
    if (time  < this.state.playedHistory.length){
      this.setState({time: time + 1},function(){
      const player = this.state.currentplayer[this.state.time+1];
      if  ((player ===3 || player ===1)&time  === this.state.playedHistory.length-1){
        this.doAi(player,this.state.sortedDeck[this.state.time+1][player],this.generatePBN(this.state.currentplayer[this.state.time], this.state.sortedDeck[this.state.time]),this.state.trumps,this.state.currentplay,this.state.pointinhand,player);
      }
    });}
  }

  render() {
    var time = this.state.time;
    var table = this.getBoardState(time,this.state.playedHistory,this.state.currentplay)
    var sortedDeck = this.state.sortedDeck[time+1]
    var south = []
    var north = [[],[],[],[]]
    var northfocus = ''
    var southfocus = ''
    var i;
    var j;
    for(j=0; j<4; j++){
      for (i = 0; i < sortedDeck[0].length; i++) {
        if(sortedDeck[0][i][1] === j){
          north[j].push(this.renderCards(i,0,'dummy',sortedDeck[0]));
        }}}

    for (i = 0; i < sortedDeck[2].length; i++) {
        south.push(this.renderCards(i,2,'hand',sortedDeck[2]));
        }
    if (this.state.currentplayer[time+1] === 0){
      northfocus = 'board-highlight'
    }
    if (this.state.currentplayer[time+1] === 2){
    southfocus = 'board-highlight'
    }

    var unseenCards = sortedDeck[1].concat(sortedDeck[3])
    var handLength = [sortedDeck[3].length,sortedDeck[1].length]
    var suits = (unseenCards.reduce((prev, obj) => {
        prev[obj[1]] = prev[obj[1]]+1;
    return prev;
}, [0,0,0,0]))


    return (
      <div className="board">
        <div className="board-side">
          <div>
          <p><b>Contract</b></p>
          <p>{this.state.bestscore-6+' '+['♠','♥','♦','♣','NT'][this.state.trumps]}</p>
          </div>
          <div className="backbutton" onClick={() => this.regressHistory()}>Back</div>

        </div>
        <div className="board-center">
        <div className={"board-dummy-row " +northfocus}>
        <div className="board-dummy-col">
        {north[0]}
        </div>
        <div className="board-dummy-col">
        {north[1]}
        </div>
        <div className="board-dummy-col">
        {north[2]}
        </div>
        <div className="board-dummy-col">
        {north[3]}
        </div>
        </div>
        <div className="board-played">
          <div className="board-played-row">
          <Card
            value= {table[0][0]}
            suit = {table[0][1]}
            order={table[0][2]}
            win = {table[0][3]}
            trumps = {this.state.trumps}
            onClick={() => this.progressHistory()}/>
          </div>
         <div className="board-played-row">
          <Card
            value= {table[3][0]}
            suit = {table[3][1]}
            order={table[3][2]}
            win = {table[3][3]}
            trumps = {this.state.trumps}
            onClick={() => this.progressHistory()}/>

          <Card
            value= {table[1][0]}
            suit = {table[1][1]}
            order={table[1][2]}
            win = {table[1][3]}
            trumps = {this.state.trumps}
            onClick={() => this.progressHistory()}/>
           </div>
          <div className="board-played-row">
          <Card
            value= {table[2][0]}
            suit = {table[2][1]}
            order={table[2][2]}
            win = {table[2][3]}
            trumps = {this.state.trumps}
            onClick={() => this.progressHistory()}/>
            </div>
            <p></p>
            <div className="backbutton" onClick={() => this.deleteFuture()}>Reset Hand</div>
        </div>
        <div className={"board-row " +southfocus}>
        {south}
        </div>
        </div>
        <div className="board-side">
          <div>
                  <p><b>Score</b></p>
                  <p>You = {this.state.NSscore[time]}</p>
                  <p>Them = {this.state.EWscore[time]}</p>
          </div>
          <Calulateprobs suits = {suits} hand = {handLength}/>
          <div className="backbutton" onClick={this.props.exit}>Menu</div>
        </div>
    </div>
    );
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      window:'select',
      levels:[],
      board : shuffledeck(),
      trumps:Math.floor(Math.random() * 5)
  }

}

componentDidMount() {fetch('https://l1yttzhaqc.execute-api.eu-west-1.amazonaws.com/default/getScenarios')
      .then((response) => response.json())
      .then(data => {
          this.setState({ levels: data.Items });
      });
   }

  unpackpbn(pbn){
    var out = [];
    var grouped = [[],[],[],[]];
    var cardRank = {'2':2,'3':3,'4':4,'5':5,'6':6,'7':7,'8':8,'9':9,'T':10,'J':11,'Q':12,'K':13,'A':14}
    var hands = pbn.substring(2).split(" ").map(x => x.split(".").map(y => y.split("").map( z => cardRank[z])))
    var i =0
    var j = 0
    for (i=0;i<4;i++){
      for (j=0;j<4;j++){
        var k = j;
        grouped[i] = grouped[i].concat( hands[i][j].map(x => [x,k,0,0]))
      }
    }

    for (i=3;i<7;i++){
        out= out.concat(grouped[i%4])
    }

    return (out)
  }

  selectlevels(){
    this.setState({
      window : 'select'
    });
  }

  newgame(pbn,trumps,minpoints,minlength,maxpoints,maxlength){
    var deck =[]
    var settrumps = 0

  if (pbn){
    deck = this.unpackpbn(pbn);
    settrumps = trumps;
  } else{  settrumps = Math.floor(Math.random() * 4);
      deck = pseudoshuffledeck(settrumps,minpoints,minlength,maxpoints,maxlength);
      console.log(deck)
      }


  this.setState({
      board : deck,
      trumps: settrumps,
      window : 'game'
    });
  }

  render() {
    var deck =this.state.board
    var trumps = this.state.trumps
    var window = this.state.window;
    var display = ''
    var data =this.state.levels;

    if (window === 'game'){ display = <Board deck = {deck} trumps = {trumps} exit = {() => this.selectlevels()}/> }
    else if(window === 'select') {display =
        <div className="center">
        <div>
        <h1>This is an app for playing bridge solitaire</h1>
        <p>This app uses the excellent double dummy solver by Bo Haglund / Soren Hein</p>
        <h2>Select one of the games from below</h2>
        </div>
      <Selector data={data} onClick={(pbn,trumps,minpoints,minlength,maxpoints,maxlength) => this.newgame(pbn,trumps,minpoints,minlength,maxpoints,maxlength)}/>
        </div>
      }


    return (
      <div className="game">

          {display}

      </div>

    );
  }
};

// ========================================

ReactDOM.render(
  <Game />,
  document.getElementById('root')
  )

})
